/**
 *  table that it was trying to access
 *https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
 */

export class ErrorResponse extends Error {
  public id: string;
  public message: string;
  public code: number;

  constructor({ message, code }: { message: string; code: number }) {
    super(message);
    this.name = this.constructor.name;
    this.id = crypto.randomUUID();
    this.message = message;
    this.code = code;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export enum ResponseStatus {
  UNSET = 'UNSET',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

/**
 *  Action Response
 *
 *  Example: ActionResponse.success('Lesson created successfully')
 *
 *
 */
export class ActionResponse {
  static create({
    status,
    message,
    code,
    redirect,
    redirectPath,
  }: {
    status?: ResponseStatus;
    message?: string;
    code: number;
    redirect?: boolean;
    redirectPath?: string;
  }): ActionResponseState {
    const response: ActionResponseState = {
      id: crypto.randomUUID(),
      status: ResponseStatus.ERROR,
      message: 'An error occurred',
      code: code,
      redirect: redirect ?? false,
      redirectPath: redirectPath ?? '/home',
    };

    switch (code) {
      case 200: {
        response.status = ResponseStatus.SUCCESS;
        response.message = message ?? 'Success';
        break;
      }
      case 400: {
        response.status = ResponseStatus.ERROR;
        response.message = message ?? 'An error occurred';
        break;
      }
      case 204: {
        response.status = ResponseStatus.UNSET;
        response.message = message ?? '';
        break;
      }
      default: {
        response.status = status ?? ResponseStatus.ERROR;
        response.message = message ?? 'An error occurred';
        break;
      }
    }

    return response;
  }

  /**
   *  Success Response
   *
   *  @param message - The message to display
   */
  static success(message?: string): ActionResponseState {
    return ActionResponse.create({ code: 200, message });
  }

  static error(message?: string): ActionResponseState {
    return ActionResponse.create({ code: 400, message });
  }

  static unset(message?: string): ActionResponseState {
    return ActionResponse.create({ code: 204, message });
  }

  static custom({
    status,
    message,
    code,
    redirect,
    redirectPath,
  }: {
    status: ResponseStatus;
    message: string;
    code: number;
    redirect?: boolean;
    redirectPath?: string;
  }): ActionResponseState {
    return ActionResponse.create({ status, message, code, redirect, redirectPath });
  }
}
