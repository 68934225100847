import type { ClassValue } from "clsx";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

export function cn(...inputs: ClassValue[]): string {
  const parsedInputs = z.array(z.custom<ClassValue>()).safeParse(inputs);

  if (!parsedInputs.success) {
    throw new Error(`Invalid class names: ${inputs.join(" ")}`);
  }

  return twMerge(clsx(parsedInputs.data));
}
