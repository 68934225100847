import { customAlphabet } from 'nanoid';
import { ulid } from 'ulidx';
import { v4 as uuidv4 } from 'uuid';

/**
 * Generates a public id with a custom alphabet and length.
 * @param length
 * @param alphabet
 * @returns Returns the nanoid
 */
export function generateNanoId(
  length = 16,
  alphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
): string {
  return customAlphabet(alphabet, length)();
}

/**
 * Generates a ULID (Universally Unique Lexicographically Sortable Identifier).
 * @returns Returns the ULID
 */
export function generateUlid(): string {
  return ulid();
}

/**
 * Generates a UUID (Universally Unique Identifier).
 * @returns {string} Returns a UUID v4 string
 */
export function generateUuid(): string {
  return uuidv4();
}

/**
 * Generates a numeric ID of specified length.
 * @param length - The length of the ID (default: 6)
 * @returns Returns a numeric ID
 */
export function generateNumericId(length = 6): string {
  return generateNanoId(length, '0123456789');
}

/**
 * Generates a short, URL-friendly ID.
 * @param length - The length of the ID (default: 8)
 * @returns Returns a short, URL-friendly ID
 */
export function generateShortId(length = 8): string {
  const alphabet = '23456789abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ';
  return generateNanoId(length, alphabet);
}

/**
 * Generates a prefixed ID.
 * @param prefix - The prefix to use
 * @param length - The length of the ID part after the prefix (default: 8)
 * @param alphabet - The alphabet to use for the ID part (optional)
 * @returns Returns a prefixed ID
 */
export function generatePrefixedId(prefix: string, length = 8, alphabet?: string): string {
  const id = alphabet ? generateNanoId(length, alphabet) : generateShortId(length);
  return `${prefix}_${id}`;
}
